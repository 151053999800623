import { IExposedBaseTableProps } from '@innovationdepartment/proxima-fbtable';
import { CreativeAd, CreativeType } from '../types';
import OkCheck from '../elements/OkCheck';
import BadCheck from '../elements/BadCheck';
import styled from '@emotion/styled';
import { Text } from '@innovationdepartment/proxima-ui';
import { formatDate, formatQuentile } from 'utils';
import {
  formatNumberToCommaSeparated,
  formatNumberToCurrency,
  formatNumberToPercentage,
} from 'utils/formatNumber';
import performanceAdsCellRenderCreativeType from './performanceAdsCellRender.creativeType';

type CellRenderer = IExposedBaseTableProps<CreativeAd>['cellRender'];

const RenderBase = styled.div`
  padding: 4px 0;
  width: 100%;
  text-align: left;
  overflow: hidden;
`;

const RenderCenter = styled(RenderBase)`
  text-align: center;
`;

const RenderRight = styled(RenderBase)`
  text-align: right;
`;

const padding = <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>;

const RenderElement = ({
  value,
  align,
}: {
  value: string | React.ReactNode;
  align?: 'left' | 'center' | 'right';
}) => {
  const Element = {
    left: RenderBase,
    center: RenderCenter,
    right: RenderRight,
  }[align ?? 'left'];

  return (
    <Element>
      <Text ellipsis variant="table1">
        {align === 'right' ? padding : null}
        {value}
      </Text>
    </Element>
  );
};

const performanceAdsCellRender: CellRenderer = (props) => {
  const { column, value } = props;

  switch (column as keyof CreativeAd) {
    case 'creativeType':
      return (
        <RenderBase>
          {performanceAdsCellRenderCreativeType({ value: value as CreativeType })}
        </RenderBase>
      );
    case 'category':
      return <RenderCenter>{value}</RenderCenter>;
    /* quentiles columns */
    case 'purchaseRoasCategoryQuentile':
    case 'ctrCategoryQuentile':
    case 'purchaseRoasBrandIdQuentile':
    case 'ctrBrandIdQuentile':
    case 'thumbstopBrandIdQuentile':
      return <RenderElement value={formatQuentile(value as unknown as number)} align="right" />;
    case 'cpaCategoryQuentile':
    case 'cpaBrandIdQuentile':
      return (
        <RenderElement value={formatQuentile(value as unknown as number, true)} align="right" />
      );
    case 'brand':
      return <RenderElement value={(value as unknown as CreativeAd['brand'])?.name as string} />;
    case 'launchDate':
      return <RenderElement value={formatDate(value)} align="right" />;
    case 'spendDays':
      return <RenderElement value={formatNumberToCommaSeparated(+(value ?? 0))} align="right" />;
    case 'lifetimeSpend':
      return <RenderElement value={formatNumberToCurrency(+(value ?? 0))} align="right" />;
    case 'spend':
      return <RenderElement value={formatNumberToCurrency(+(value ?? 0))} align="right" />;
    case 'adset':
      // TODO(Jenky): figure out how to handle this when passing objects
      return (
        <RenderElement
          value={(value as unknown as CreativeAd['adset'])?.attributionWindows as string}
        />
      );
    case 'configuredStatus':
      return (
        <RenderElement value={value === 'ACTIVE' ? <OkCheck /> : <BadCheck />} align="center" />
      );
    case 'purchaseRoas':
      const purchaseRoas = value ? `${(+value).toFixed(2)}x` : '-';
      return <RenderElement value={purchaseRoas} align="right" />;
    case 'thumbstopRate':
      const formattedThumbstopRate = formatNumberToPercentage(+(value ?? 0) / 100);
      return <RenderElement value={value ? formattedThumbstopRate : '-'} align="right" />;
    default:
      return <RenderElement value={value as string} />;
  }
};

export default performanceAdsCellRender;
